.terms-content {
  padding: 40px;
}

.terms-heading {
  font-size: 30px;
}

@media (max-width: 768px) {
  .terms-main {
    text-align: center;
  }
  .terms-content {
    padding: 20px;
  }
  .terms-heading {
    font-size: 30px;
  }
}
