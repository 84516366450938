.inputStyle {
    width: 3rem !important;
    height: 3rem;
    margin: 0 1rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .3);
}

.timerText {
    color: #777
}

.resend {
    color: #1473e2;
}

.resend.disabled {
    cursor: not-allowed;
    color: #ccc;
    pointer-events: none;
}

.otpContainer {
  display: flex !important;
  justify-content: space-between !important;
  width: 100%; /* Adjust as necessary */
}

  @media (max-width: 600px) {
  
    .otpInput {
      width: 33px !important;
      height: 33px !important;
      font-size: 16px !important;
    }
  
    .timerText {
      font-size: 12px !important;
    }
  
     }
  