.HomeBannerBg {
  background: #0B3013 !important;
  border-bottom: 1px solid #0b2c10 !important;
  padding: 14px 0px !important;
}

.pageMenuHeader {
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  padding: 7px 14px !important;
}

.catButton {
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  padding: 25px 20px !important;
  background: #3d5942 !important;
  border-radius: 48px !important;
  line-height: 0 !important;
  height: max-content !important;
  /* transition: background-color 0.3s ease !important; */
}

.catButton:hover {
  background: #557d5c !important;
}

.SignInBtn {
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  padding: 7px 14px !important;
}

.JoinBtn {
  background: #fff !important;
  color: #000 !important;
  padding: 8px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  border-radius: 25px !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  margin: 10px !important;
  text-transform: capitalize !important;
}

.JoinBtn:hover {
  background: #fff !important;
}

.catmenu {
  padding-right: 10px !important;
}

.mainHeader {
  align-items: center !important;
}

.stickyHeader {
  transition: top 0.3s ease !important;
  background-color: #0b2c10 !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

