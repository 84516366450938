.carousel {
    position: relative;
    width: 100%;
    height: 100px; /* Set to your desired height */
    overflow: visible;
}

.carousel-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.carousel-item.active {
    opacity: 1;
}

.carousel-controls {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
}

.carousel-controls button {
    width: 10px;
    height: 10px;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    opacity: 0.5;
    cursor: pointer;
}

.carousel-controls button.active {
    opacity: 1;
    background-color: #333;
}
