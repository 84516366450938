.ulListContent {
    padding-left: 0; /* Remove padding to align with the heading */
    margin: 0; /* Remove default margin */
    list-style-type: disc; /* Use circle bullets */
    padding-left: 20px;
}

.listContent {
    text-align: start;
    font-size: 18px;
    line-height: 1.7;
}