.testimonialContainer{
    background: #0B3013;
    padding: 10px 30px 10px 30px;
    border-radius: 20px;
    height: 250px; /* Set a fixed height */
    overflow: hidden; /* Handle overflow */
    display: flex; /* Use flexbox for vertical centering */
    flex-direction: column; /* Arrange content vertically */
    justify-content: space-between; /* Vertically space content */
}

.testimonialBody{
    padding-top: 1rem;
    padding-bottom: 1rem;
    flex-grow: 1; /* Allow the body to grow within the container */
    overflow: hidden; /* Handle overflow within the body */
}

.testimonialHeader{
    border-top: 1px solid #fff;
    display: flex;
    color: #fff;
    padding-top: 20px;
}

.subText{
    padding: 0;
    margin-left: 12px;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 300;
}
.testimonialBodyText{
    color: #fff;
    font-weight: 300;
    overflow: hidden; /* Handle overflow within the body text */
}
.AuthorText{
    color: #fff;
}
.nameContainer{
    padding-bottom: 1.5rem;
}


.slick-dots {
    position: absolute;
    bottom: -50px;
}
.slick-dots li button:before{
    font-size: 20px;
    color: #0B3013 !important;
}