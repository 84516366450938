.HomeBanner{
    background-image: url('../../../Assets/Images/HeroBG.png');
    background-position: bottom;
    background-size: cover;
    display: flex;
    height: 1000px;
    
}
.girlBanner{
    display: flex;
    margin-left: auto;
}

.heroheading{
    color: #fff;
    font-size: 60px;
    line-height: 1.3;
    font-weight: 700;
    padding-top: 12px;
    padding-bottom: 12px;
}

.subHead{
    color: #fff;
    font-size: 23px;
    line-height: 1.5;
    font-weight: 400;
    padding-top: 15px;
}


/* Custom Search CSS */
.mainCustomCat{
    background: #fff;
    margin-top: 40px;
    margin-bottom: 40px;
    border-radius: 60px;
    padding: 10px;
    /* width: 100vh;
    max-width: 80vh; */
    padding-left: 16px;
}

.catTextBox{
    padding-top: 7px;
    padding-bottom: 7px;
    margin-left: 12px;
    border: 0; 
    font-size: 16px;
    width: 100%;
    border-right: 2px solid #e2e2e3;
}
.catTextBox:focus-visible{
    outline: 0 !important;
    box-shadow: none !important;
    border: 0 !important;    
}
.catTextBox:focus{
    outline: 0 !important;
    box-shadow: none !important;
    border: 0 !important;
}
.SearchBtnCat{
    text-transform: capitalize !important;
    font-size: 18px!important;
    background: #0B3013!important;
    color: #fff!important;
    width: 100%;
    border-radius: 60px!important;
    padding: 13px!important;
}

.SearchBtnCat:hover{
    background: #0B3013!important;
}

.counterDivBanner{
    display: flex;
    padding-top: 40px;
}

.stCounter{
    display: flex;
    color: #fff;
    margin-bottom: 10px !important;
    margin-top: 0 !important;
}
.counter{
    padding-left: 12px;
    padding-right: 12px;
}

.counter:first-child{
    padding-left: 0px;
}

.counterGirl{
    background: #fff;
    border-radius: 40px;
    width: auto;
    max-width: fit-content;
    padding: 16px;
    min-width: 135px;
}

.insideFreelance{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.activeTxt{
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 12px;
    font-weight:500;
    display: block;
    color: #000;
    width: max-content;
}

.mainGirlBlock{
    position: relative;
}

.floatingCounter{
    position: absolute;
}
.mainGirlBlock {
    position: relative;
}

.floatingCounterContainer,
.secCounterContainer,
.projCounterContainer {
    position: absolute;
    animation: floatAnimation 3s infinite alternate ease-in-out; /* Adjust animation duration and timing function as needed */
}

@keyframes floatAnimation {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-20px); /* Adjust the float height as needed */
    }
}

.secCounterContainer{
    position: absolute;
}

.floatingCounterContainer {
    right: 120px;
}

.projCounterContainer {
    min-width: 200px;
    left: 120px;
    bottom: 80px;
}
.secCounterContainer {
    position: absolute;
    right: -100px;
    bottom: -40px;
}
.insideFreelance1{
    display: flex;
    align-items: center;
}
.activeTxt1{
    margin-top: 10px;
    margin-bottom: 5px;
}

.miniCount{
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.secue{
    margin: 10px 10px 0px 0px;
}

@media only screen and (min-width: 1000px) {
    .floatingCounterContainer {
      right: 145px;
    }
    .secCounterContainer {
      right: 0px;
      bottom: -40px;
    }
    .projCounterContainer {
      left: 10px;
      bottom: 80px;
    }
  }
  
  /* Add additional media queries for other screen sizes if needed */
  