.floating-chatbot {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 350px;
  max-width: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  z-index: 1000;
  background-color: #fff;
  transition: transform 0.3s ease-in-out;
}

/* Target the chatbot body elements with a more specific selector */
.floating-chatbot .rsc-content > div {
  background-color: #ffffff !important; /* Set the desired background color */
  color: #000000 !important; /* Set the desired text color */
}

.floating-chatbot .rsc-container {
  height: 470px;
}

/* Override any other potential styles */
.floating-chatbot .rsc-content {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #ffffff !important; /* Set the desired background color */
}

.floating-chatbot .rsc-input {
    
  position: relative;
  padding-left: 50px ;
  border-top: 1px solid #16a34a;
}

.floating-chatbot.hidden {
  transform: scale(0);
}

.chatbot-toggle-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #16a34a;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 1001;
}

.chatbot-toggle-button:hover {
  background-color: #16a34a;
}

.chatbot-header {
  background-color: #f8faff;
  color: #000;
  padding: 10px;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.chatbot-bubble {
  background-color: #f8faff !important;
  color: #000 !important;
}

.user-bubble {
  background-color: #16a34a !important;
  color: #000 !important;
  font-family: inherit;
}
.attachment-icon-container {
    cursor: pointer;
  position: absolute;
  /* top: 0; */
  bottom: 0;
  z-index: 9999;
  padding-bottom: 20px;
  padding-left: 12px;

}

.hidden-file-input {
  display: none;
}
