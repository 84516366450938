
.copy-text {
	position: relative;
	background: #fff;
	border: 1px solid #ddd;
	border-radius: 12px;
    border: 1px solid #0B3013;
	display: flex;
    justify-content: space-between;
}
.copy-text input.text {
	padding: 10px;
	font-size: 18px;
	color: #555;
	border: none;
	outline: none;
    margin-left: 7px;
}
.copy-text button {
	padding: 10px;
	background: #0B3013;
	color: #fff;
	font-size: 18px;
	border: none;
	outline: none;
    border:1px solid #0B3013;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
	cursor: pointer;
}

.copy-text button:active {
	background: #0B3013;
}
.copy-text button:before {
	content: "Copied";
	position: absolute;
	top: -45px;
	right: 0px;
	background: #0B3013;
	padding: 8px 10px;
	border-radius: 20px;
	font-size: 15px;
	display: none;
}
.copy-text button:after {
	content: "";
	position: absolute;
	top: -20px;
	right: 25px;
	width: 10px;
	height: 10px;
	background: #0B3013;
	transform: rotate(45deg);
	display: none;
}
.copy-text.active button:before,
.copy-text.active button:after {
	display: block;
}