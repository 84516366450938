.signinText {
  /* padding-top: 12px; */
  text-align: end;
}
.SigninTextJoin {
  color: #1565D8;
  cursor: pointer;
  font-weight: 500;
}
.sognupIntro {
  display: flex;
  align-items: baseline;
  flex-direction: column;
}

.beginText {
  text-align: start;
  color: #7777;
  font-weight: 400;
}
.employeeInner {
  display: flex;
  background: #fff;
  box-shadow: 0 2px 14px 1px rgba(0, 0, 0, 0.06);
  padding: 24px 12px 24px 24px;
  border-radius: 6px;
  margin-bottom: 16px;
  align-items: center;
}

.employeeInner.active {
  border: 1px solid #0b3013;
  background: #f7fdf9;
}

.employeeInner:hover {
  cursor: pointer;
  border: 1px solid #0b3013;
}

.employeeInner:hover .iconArrow {
  opacity: 1;
}

.iconArrow {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.employeeInner:hover .iconArrow {
  opacity: 1;
}

.iconArrow:hover {
  cursor: pointer;
}

.employeText {
  display: flex;
  flex-direction: column;
}

.sideBg {
  background: #f8f8f9;
  height: 100vh;
  max-height: calc(100vh - 64px);
  
}


.iconArrow12{
  display: flex;
    margin-bottom: auto;
    margin-top: 14px;
    margin-left: 7px;
    cursor: pointer;
    position: absolute;
    position: absolute;
    top: 0;
    left: 0;
}