.css-e1kh2d-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.MuiInputBase-root.selectCountry{
    border: 0;
}

.caretCountry{
    position: absolute;
    bottom: 0;
    right: -23px;
    top: 14px;
}
