.membership-grid:hover {
    border: 1.31px solid #E9E9E9; /* Change border color to green on hover */
    border-radius: 26px;
    cursor: pointer;
  }
  .membership-grid {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .buy-button {
    background: #F1FCF4;
  }

  .membership-grid:hover .buy-button {
    background: #0B3013 !important;
    color: #fff;
  }
  
  /* .slick-prev:before, .slick-next:before{
    color:green ;
  }

   */