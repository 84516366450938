.textHeading{
    display: flex;
    justify-content: center;
    color: #0B3013;
    font-size: 32px;
    font-weight: normal;
}

.HomePartners{
    margin-top: -10%;

}

  
  .slick-slide {
    margin: 0 0px; /* Adjust spacing between partner logos */
  }
  .partners-slider{
    margin-top: 30px;
  }