
.footer {
    width: 100%;
    bottom: 0;
    left: 0;
    border-top-right-radius: 130px;
  }
  #footerBG{
    background-image: url('../../Assets/Images/FooterBG.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .footer .heading {
    text-transform: uppercase;
    margin: 0 auto;
    margin-bottom: 3rem;
    font-family: inherit
  }
  
  .footer .content {
    display: flex;
    justify-content: space-around;
  }
  
  .footer .content p {
    margin-bottom: 1.3rem;
    color: rgba(0, 0, 0, 0.829);
  }
  
  .footer .content a {
    text-decoration: none;
    color: rgb(0, 0, 0, 0.829);
  }
  
  .footer .content a:hover {
    border-bottom: 1px solid rgba(0, 0, 0, 0.829);
  }
  
  .footer .content h4 {
    margin-bottom: 1.3rem;
    font-size: 19px;
  }
  
  footer {
    text-align: center;
    padding-bottom: 2rem;
  }
  
  footer hr {
    margin: 2rem 0;
  }

  .headSection{
    display: flex;
    justify-content: space-between;
  }
  
  .socialIco{
    padding-left: 8px;
    padding-right: 8px;
  }

  .mainFooteHead{
    color: #fff;
  }
  .textSubFoote{
    color: #fff !important;
    opacity: 0.7;
    font-weight: normal;
  }


.searchbox-wrap {
    display: flex;
    margin-top: 8%;
    margin-left: auto;
    margin-right: auto;
  }
  .searchbox-wrap input {
    flex: 1;
    padding: 20px 20px;
    font-size: 16px;
    -webkit-border-top-left-radius: 12px;
    -webkit-border-bottom-left-radius: 12px;
    -moz-border-radius-topleft: 12px;
    -moz-border-radius-bottomleft: 12px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    box-shadow: none;
    border: none;
    color: #fff;
    background: #3C5942;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.19);
  }

  .searchbox-wrap input::placeholder {
    color: #fff;
    font-size: 18px;
  }
  .searchbox-wrap button {
    padding-right: 10px;
    -webkit-border-top-right-radius: 12px;
    -webkit-border-bottom-right-radius: 12px;
    -moz-border-radius-topright: 12px;
    -moz-border-radius-bottomright: 12px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: 5px 4px 6px rgba(0, 0, 0, 0.19);
    border: none;
    cursor: pointer;
    cursor: hand;
  }
  .searchbox-wrap button span {
    padding: 24px 24px;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 300 ;
    color: #000;
    border-radius: 20px;
  }

  .downloadImage{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .Lnk{
    text-decoration: none;
  }
  @media (max-width: 767px) {
    .footer .content {
      display: flex;
      flex-direction: column;
      font-size: 14px;
    }
  
    .footer {
      position: unset;
    }
  }
  
  @media (min-width: 768px) and (max-width: 1024px) {
    .footer .content,
    .footer {
      font-size: 14px;
    }
  }
  
  @media (orientation: landscape) and (max-height: 500px) {
    .footer {
      position: unset;
    }
  }
  