.SignUpBtn{
    background: #0B3013 !important;
    width: 100% !important;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}

.SignUpBtn:hover{ 
    background: #0B3013;

}
.SignUpGoogle{
    background: #fff!important;
    width: 100%!important;
    padding-top: 14px!important;
    padding-bottom: 14px!important;
    color: #000!important;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08)!important;
}
.unRe{
    text-decoration: underline;
}