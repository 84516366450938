.chartBtn {
    padding: 10px 14px;
    border: 1px solid #ddd;
    background-color: transparent;
    color: #333;
    cursor: pointer;
    border: 0;
    font-size: 16px;
  }
  
  .chartBtn.active {
    font-weight: 700;
    background-color: transparent; /* Transparent background for the active button */
    border-color: #333; /* Optional: change border color to highlight the active button */
  }
  
  .chartBtn.inactive {

    color: #a4a4a4;
    font-weight: 400;

  }
  