.firstMakeSection{
    background-image: url('../../../Assets/Images/WavesMake1.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.secondMakeSection{
    position: relative;
    background-image: url('../../../Assets/Images/SectionReal2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.gloa{
    justify-content: space-evenly;
}

.containerReview {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 100px 50px;
    padding: 100px 50px;
    padding-left: 0;
}

.containerReview .card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 280px;
    max-width: 100%;
    height: 300px;
    background: #FFF;
    border-radius: 20px;
    transition: 0.5s;
    box-shadow: 0 35px 80px rgba(0, 0, 0, 0.15);
}

.containerReview .card:hover {
    height: 400px;
}

.containerReview .card .img-box {
    position: absolute;
    top: 0px;
    width: 300px;
    height: 220px;
    background: #fff;
    border-radius: 12px;
    overflow: hidden;
    transition: 0.5s;
}

.containerReview .card:hover .img-box {
    top: -100px;
    scale: 0.75;
    box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);
}

.containerReview .card .img-box img {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    /* top: 0;
    left: 0;
    width: 95%;
    height: 95%;
    object-fit: cover; */

}

.containerReview .card .content {
    position: absolute;
    top: 235px;
    width: 100%;
    height: 50px;
    padding: 0 30px;
    text-align: center;
    overflow: hidden;
    transition: 0.5s;
}

.containerReview .card:hover .content {
    top: 130px;
    height: 250px;
}

.containerReview .card .content h2 {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--clr);
}

.containerReview .card .content p {
    color: #333;
}

.containerReview .card .content a {
    position: relative;
    top: 15px;
    display: inline-block;
    padding: 12px 25px;
    text-decoration: none;
    background: var(--clr);
    color: #FFF;
    font-weight: 500;
}

.containerReview .card .content a:hover {
    opacity: 0.8;
}

.makeBanner{
    max-width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    object-fit: contain;
}

@media (max-width: 480px) {
    .containerReview .card {
        width: 230px;
        border-radius: 15px;
    }

    .containerReview .card .img-box {
        width: 185px;
        border-radius: 10px;
    }

    .containerReview .card .content p {
        font-size: 0.8rem;
    }

    .containerReview .card .content a {
        font-size: 0.9rem;
    }
}