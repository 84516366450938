.search {
  position: relative;
  color: #aaa;
  font-size: 16px;
}

.search {
  display: inline-block;
  width: 100%;
}

.search input {
    padding: 20px;
width: 100%;
  background: #F7F7F7;
  border: 0;
  border-radius: 25px;
  max-width: -webkit-fill-available;
}

.search input::placeholder {
    font-size: 16px;
    color: #212529;
    opacity: 0.75;
}

.search input {
  text-indent: 32px;
}
.search .fa-search {
  position: absolute;
  top: 15px;
  left: 10px;
}

.search .fa-search {
  right: auto;
  left: 15px;
}

/* EmployeeMessage.css */

/* Custom scrollbar */
.chat-list-scrollbar {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #000 transparent; /* For Firefox */
  }
  
  /* WebKit browsers (Chrome, Safari) */
  .chat-list-scrollbar::-webkit-scrollbar {
    width: 6px; /* Adjust the width as needed */
  }
  
  .chat-list-scrollbar::-webkit-scrollbar-track {
    background: transparent; /* Make the scrollbar track transparent */
  }
  
  .chat-list-scrollbar::-webkit-scrollbar-thumb {
    background: #000; /* Color of the scrollbar handle */
    border-radius: 3px; /* Round the corners of the scrollbar handle */
  }
  
  .chat-list-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #000; /* Change color on hover if desired */
  }
  
  .chat-chat-searchbox-wrap{
    display     : flex; 
    margin-left : auto;
    margin-right: auto;
  }

.chat-searchbox-wrap{
    width: 100%;
    display: flex;
    align-items: center;
}
.chat-searchbox-wrap input {
    flex: 1;
    width: 100%;
    padding: 20px 20px;
    font-size: 16px;
    -webkit-border-top-left-radius: 25px;
    -webkit-border-bottom-left-radius: 25px;
    -moz-border-radius-topleft: 25px;
    -moz-border-radius-bottomleft: 25px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    box-shadow: none;
    border: none;
  }

  .chat-searchbox-wrap button span {
    padding: 15px 20px;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 300 ;
    color: #fff;
    background-color: #0B3013;
    border-radius: 25px;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.19);
    display:flex;
    align-items: center;
  }
.zzzz{
    background: transparent;
    border: 0;
}
.imageSend{
    padding-left:5px;
}
.messageText:focus{
    box-shadow: none !important;
}