.react-tel-input .form-control{
    padding-left: 40px;
    width: 100%;
    padding-top: 27px;
    padding-bottom: 27px;
}
.react-tel-input .flag-dropdown {
    background: transparent;
    border: 0;
}

.react-tel-input .flag-dropdown:hover { 
    border: 1px solid #ccc;
    border-right: 0;
}